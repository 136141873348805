
import { $gettext } from 'vue-gettext';
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import { defaultDesignation, Designation, useOperatingSystemStore } from '@/stores/operatingSystem';
import { isEqual, OptionalKeys } from '@/utils/object';
import { removeHTML } from '@/utils/string';
import { slashIcon } from '../icon/BpIcon';
import { useFormErrors } from '@/utils/form-errors';
import { useRouter } from 'vue-router';
import BpToast from '../toast/BpToasts';
import BpWysiwygEditorColorPickerVue from '@/components/wysiwyg-editor/BpWysiwygEditorColorPicker.vue'
import clone from '@sahnee/clone';

export default defineComponent({
  name: 'bp-operating-system-designation-editor',
  components: {
    BpWysiwygEditorColorPicker: BpWysiwygEditorColorPickerVue,
  },
  props: {
    modelValue: Object as PropType<Designation>,
    loading: Boolean,
  },
  setup(props) {
    ///-------------------------------------------------------------------
    /// ROUTER
    ///-------------------------------------------------------------------

    const router = useRouter();
    const isEdit = computed(() => router.currentRoute.value.name?.toString().endsWith('edit'));

    ///-------------------------------------------------------------------
    /// FOLDER
    ///-------------------------------------------------------------------

    const initialDesignation: OptionalKeys<Designation, '_id' | '_rev'> = {
      ...defaultDesignation,
    };

    const designation = ref<OptionalKeys<Designation, '_id' | '_rev'>>({ ...clone(initialDesignation), ...props.modelValue ? clone(props.modelValue) : {} });
    watch(() => props.modelValue, reset, { immediate: true, deep: true });

    ///-------------------------------------------------------------------
    /// RESET
    ///-------------------------------------------------------------------

    function reset() {
      if (props.modelValue && isEqual(designation.value, props.modelValue)) { return }
      designation.value = { ...clone(initialDesignation), ...props.modelValue ? clone(props.modelValue) : {} };
    }

    ///-------------------------------------------------------------------
    /// ERROR
    ///-------------------------------------------------------------------

    const { errors, setError } = useFormErrors<Designation>();

    ///-------------------------------------------------------------------
    /// SAVE
    ///-------------------------------------------------------------------

    const store = useOperatingSystemStore();

    async function save() {
      let response;
      designation.value.doc_type = 'operating_system_designation';
      if (designation.value._id) {
        response = await store.update(designation.value);
      } else {
        response = await store.create(designation.value);
      }
      if (response?.success) {
        BpToast.show({
          color: 'green',
          title: designation.value._id
            ? $gettext('Designation successfully updated')
            : $gettext('Designation successfully created'),
          content: designation.value._id
            ? $gettext('The designation was successfully updated.')
            : $gettext('The designation was successfully created.'),
          icon: 'circle-check',
        });
        router.replace({ name: 'admin.operating-system.overview' });
      } else {
        BpToast.show({
          color: 'red',
          title: designation.value._id
            ? $gettext('Failed to update designation')
            : $gettext('Failed to create designation'),
          content: designation.value._id
            ? $gettext('The designation could not be updated: %{error}', { error: response?.error })
            : $gettext('The designation could not be created: %{error}', { error: response?.error }),
          icon: 'circle-xmark',
        });
      }
    }

    const saveDisabled = computed(() => errors.value.size > 0);

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      designation,
      errors,
      isEdit,
      removeHTML,
      reset,
      save,
      saveDisabled,
      setError,
      slashIcon,
      store,
    }
  }
})


