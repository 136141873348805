
import { defineComponent, ref, watch } from 'vue';
import { Designation, useOperatingSystemStore } from '@/stores/operatingSystem';
import { useRouter } from 'vue-router';
import BpOperatingSystemDesignationEditorVue from '@/components/operating-system/BpOperatingSystemDesignationEditor.vue';

export default defineComponent({
  name: 'operating-system-designation-editor',
  components: {
    BpOperatingSystemDesignationEditor: BpOperatingSystemDesignationEditorVue,
  },
  setup() {
    const router = useRouter();

    const store = useOperatingSystemStore();

    const operatingSystem = ref<Designation>();

    function loadOperatingSystem() {
      if (router.currentRoute.value.name && (router.currentRoute.value.name as string).endsWith('edit')) {
        const doc = store.getById(router.currentRoute.value.params.id as string)
        operatingSystem.value = doc as Designation;
      }
    }

    watch(() => store.isLoading(), () => {
      if (!store.loaded || store.isLoading()) { return }
      loadOperatingSystem()
    }, { immediate: true });

    return {
      operatingSystem,
      store,
    }
  }
});
