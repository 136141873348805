import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_input = _resolveComponent("bp-input")!
  const _component_bp_wysiwyg_editor_color_picker = _resolveComponent("bp-wysiwyg-editor-color-picker")!
  const _component_bp_label = _resolveComponent("bp-label")!
  const _component_bp_form_button = _resolveComponent("bp-form-button")!
  const _component_bp_card = _resolveComponent("bp-card")!

  return (_openBlock(), _createBlock(_component_bp_card, {
    class: "bp-operating-system-designation-editor",
    loading: _ctx.loading,
    "fill-height": "",
    "fill-width": ""
  }, {
    header: _withCtx(() => [
      (!_ctx.isEdit)
        ? (_openBlock(), _createBlock(_component_translate, {
            key: 0,
            tag: "h2"
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("New designation")
            ])),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_translate, {
            key: 1,
            tag: "h2",
            "translate-params": { designation: _ctx.removeHTML(_ctx.store.getName(_ctx.designation)) }
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode("Edit designation \"%{designation}\"")
            ])),
            _: 1
          }, 8, ["translate-params"]))
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_bp_form_button, {
        type: "save",
        disabled: _ctx.saveDisabled,
        errors: _ctx.errors,
        action: _ctx.save
      }, null, 8, ["disabled", "errors", "action"]),
      _createVNode(_component_bp_form_button, {
        type: "reset",
        action: _ctx.reset
      }, null, 8, ["action"]),
      _createVNode(_component_bp_form_button, {
        type: "cancel",
        to: { name: 'admin.operating-system.overview' }
      })
    ]),
    default: _withCtx(() => [
      (_ctx.designation)
        ? (_openBlock(), _createElementBlock("form", {
            key: 0,
            class: "bp-operating-system-designation-editor__form",
            onSubmit: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"]))
          }, [
            _createVNode(_component_bp_input, {
              label: _ctx.$gettext('Name'),
              onChangeValid: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setError('name', _ctx.$gettext('Name'), $event))),
              required: "",
              maxlength: 200,
              modelValue: _ctx.designation.name,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.designation.name) = $event))
            }, null, 8, ["label", "modelValue"]),
            _createVNode(_component_bp_label, {
              label: _ctx.$gettext('Color')
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createVNode(_component_bp_wysiwyg_editor_color_picker, {
                    modelValue: _ctx.designation.color,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.designation.color) = $event))
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _: 1
            }, 8, ["label"])
          ], 32))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["loading"]))
}